<template>
  <div class="footer-index-content w100">
    <div class="main-width text-al">
      <div class="flex-between">
        <div class="first">
          <div class="footer-item-title blod">About</div>
          <div class="footer-item-content-item">
            <a href="/clausesView" class="under-line">Terms of Use</a>
          </div>
          <div class="footer-item-content-item">
            <a href="/Statement" class="under-line">Privacy Policy</a>
          </div>
        </div>
        <div class="flexitem">
          <div class="footer-item-title blod">Contact Us</div>
          <div class="footer-item-content-item">
            <a href="">Email：zhaosiyuan@wemedia.cn</a>
          </div>
          <div class="footer-item-content-item">
            <!-- <a href="">Business Collaboartions</a>  -->
            <!-- // 商业合作 -->
          </div>
        </div>
        <!-- <div class="item-width">
          <div class="footer-item-title blod">Community</div>
          <div class="footer-item-content-item">
            <a href="">Tiktok</a>
          </div>
        </div> -->
      </div>
      <p class="footer-conpany-s text-al">
        © ShortABC，All Rights Reserved GUANGXI NANNING YOUJIN INFORMATION
        TECHNOLOGY CO., LTD.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.footer-index-content {
  position: absolute;
  bottom: 0;
  color: #fff;
  left: 0;
  font-size: 14px;
  z-index: 999;
  padding: 30px 0 20px;
  background: #212121;
  .first {
    flex: 1;
  }
  .footer-item-title {
    font-size: 16px;
  }
  .footer-item-content-item {
    color: #f0f0f0;
  }
  .item-width {
    width: 200px;
  }
  .footer-conpany-s {
    margin-top: 30px;
  }
}
/* 适配 H5 和 PC */
@media (max-width: 768px) {
  .footer-index-content .footer-item-title {
    font-size: 12px;
  }
  .footer-item-content-item a {
    font-size: 10px;
  }
  .first {
    width: 30%;
  }
  .footer-conpany-s {
    font-size: 12px;
  }
}
</style>