import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import router from './router';
Vue.config.productionTip = false
import 'vant/lib/index.css';
import '@/assets/css/main.css'
import '@/assets/css/index.less'
// 使用Vue Router插件
Vue.use(Vant);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
